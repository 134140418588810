<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Clientes</h6>
        </div>
      </div>
    </base-header>
    <!--Form modal-->
    <modal :show.sync="assignUserModal"
           size="sm"
           body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Informe para qual usuário deseja atribuir este pix</small>
          </div>
        </template>

        <template>
          <div class="row">
            <div class="col-md-9">
              <base-input label="Usuário de Destino" :rules="{required: true, min: 4, max: 50}">
                <div class="form-group input-group">
                  <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                  <input v-model="destination" aria-describedby="addon-right addon-left"
                         class="form-control"
                         name="Usuário de Destino">
                </div>
              </base-input>
            </div>
            <div class="col-md-3" style="padding-top: 8px">
              <base-input label=" " >
                <button type="button" class="btn btn-primary"  @click="searchUser">
                  <span class="btn-label"><i class="fas fa-search"></i></span>
                </button>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <card v-if="!isNullOrEmpty(destinationName)">
                <div class="col-md-12 text-center" v-if="!isNullOrEmpty(destinationName)">
                  <label class="control-label">Nome Destino</label>
                  <p class="form-control-static">{{destinationName}}</p>
                </div>
                <div class="col-md-12" v-if="!isNullOrEmpty(destinationSocialNumber)"
                     style="text-align: center">
                  <label class="control-label">CPF/CNPJ</label>
                  <p class="form-control-static">{{destinationSocialNumber}}</p>
                  <input type="hidden" v-mask="['###.***.***-##', '##.###.###/####-##']"
                         v-model="destinationSocialNumber"/>
                </div>
                <div class="col-md-12" v-if="!isNullOrEmpty(destinationEmail)" style="text-align: center">
                  <label class="control-label">E-mail Destinatário</label>
                  <p class="form-control-static">{{destinationEmail}}</p>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <card v-if="!isNullOrEmpty(destinationName)">
                <div class="col-md-12 text-center">
                  <button type="button" class="btn btn-primary m-3" @click="doAssign()">
                    <i slot="label" class="fas fa-exchange-alt"></i> Atribuir
                  </button>
                </div>
              </card>
            </div>
          </div>

        </template>
      </card>
    </modal>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-header">
            <h4>Buscar Conta</h4>
          </div>
          <div class="card-body row">
            <div class="col-md-3">
              <base-input label="Documento / Nome pagador"
                          v-model="search.key"/>
            </div>
            <div class="col-md-3">
              <base-input label="Status">
                <el-select size="large"
                           placeholder="Selecionar"
                           class="select-default"
                           v-model="search.status">
                  <el-option :value="null"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="IMPORTED"
                             class="select-default"
                             label="Importado"/>
                  <el-option value="ASSIGNED"
                             class="select-default"
                             label="Associado"/>
                  <el-option value="UNASSIGNED"
                             class="select-default"
                             label="Desassociado"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label=" ">
                <button type="button" class="btn btn-primary" @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </button>
              </base-input>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%"
                        empty-text="Nenhum pix encontrado no pool para os filtros informados.">
                <el-table-column :min-width="200" label="Nome">
                  <template slot-scope="props">
                      <span>
                        <span><b>{{props.row.payerName}}</b></span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="Doc.">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.payerDocumentType}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Núm. Documento">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.payerDocument}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Usuário Atrib.">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.assignedTo == null ? '' : '@'+props.row.assignedTo}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Data">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.assignmentDate}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Valor">
                  <template slot-scope="props">
                      <span>
                        <span>{{toMoney(props.row.payedValue)}}</span>
                      </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="90" label="Status">
                  <template slot-scope="props">
                      <span>
                        <span>{{statusToLabel(props.row.status)}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="230" class-name="td-actions">
                  <template slot-scope="props">
                    <button class="btn btn-sm btn-success" type="button" title="Associar"
                            @click="assign(props.row.id)">
                      <i class="ni ni-fat-add"></i>
                    </button>
                    <button class="btn btn-sm btn-danger" type="button" title="Desassociar"
                            @click="unassign(props.row.id)">
                      <i class="ni ni-fat-delete"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {failWs, postWs} from "@/ws.service"
  import {isNullOrEmpty, toMoney} from "@/util/core.utils"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {

    },
    data() {
      return {
        assignUserModal: false,
        search: {
          key: '',
          status: null
        },
        tableData: [],
        destination: '',
        destinationName: '',
        destinationSocialNumber: '',
        destinationEmail: '',
        pooledId: null,
      }
    },
    watch: {
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      isNullOrEmpty,
      toMoney,
      resetDefaultValues() {
        this.search.key = ''
        this.search.status = null,
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.loadData()
      },
      loadData() {
        postWs("/bo/pix/pooled/list",
          true, null,
          {
            key: this.search.key,
            status: this.search.status
          }, this.successLoadData,
          error => {
            if(error.response.status === 404){
              this.tableData = [];
            }
          })
      },
      successLoadData(response) {
        this.tableData = response.data
      },
      searchUser() {
        this.destination = this.destination.replace(' ', '').replace('@', '').toLowerCase()
        if (this.destination == '') {
          this.$swal({
            title: 'Aviso!',
            text: 'O campo Usuário de Destino é obrigatório!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }
        this.destinationName = ''
        this.destinationSocialNumber = ''
        postWs("/acc/find",
          true, {username: this.destination, self: true}, null,
          (response) => {
            this.destinationName = response.data.destinationName
            this.destinationSocialNumber = response.data.destinationSocialNumber
            this.email = response.data.email
            if (!isNullOrEmpty(response.data.destinationSocialNumber) && response.data.destinationSocialNumber.length > 11) {
              this.transferToLegalPerson = true
            } else {
              this.transferToLegalPerson = false
            }
          }, (error) => {
            this.destinationName = ''
            this.destinationSocialNumber = ''
            if (error.response.status == 404) {
              this.$swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada para receber transações.',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                icon: 'warning'
              })
            }
          })
      },
      assign(id) {
        this.assignUserModal = true;
        this.pooledId = id;
      },
      doAssign(){
        this.assignUserModal = false;
        postWs("/bo/pix/pooled/assign",
          true, null,
          {
            id: this.pooledId,
            assignedTo: this.destination
          }, response =>{
            if(response.data.response === 'OK'){
              this.$swal({
                title: 'Sucesso!',
                html: '<p>Código do Comprovante:<br>' + response.data.authentication + '</p>',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-info btn-fill',
                  cancelButton: 'btn btn-success btn-fill'
                },
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Copiar Código',
                cancelButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.copyAuthenticationCode()
                }
              })
            } else {
              this.$swal({
                title: 'Ops!',
                text: 'Ocorreu algum problema. Tente novamente.',
                timer: 5000,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                icon: 'error'
              })
            }
            this.loadData();
          },
          error => {
            this.$swal({
              title: 'Ops!',
              text: 'Ocorreu algum problema. Tente novamente.',
              timer: 5000,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              icon: 'error'
            })
          })
      },
      unassign(id) {
        this.pooledId = id
        this.$swal({
          title: 'Atenção!',
          text: 'Tem certeza que deseja desatribuir esta transação?',
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            postWs("/bo/pix/pooled/unassign",
              true, null,
              {
                id: this.pooledId,
              }, response => {
                this.loadData()
              }, error => {
                this.$swal({
                  title: 'Ops!',
                  text: 'Ocorreu algum problema. Tente novamente.',
                  timer: 5000,
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-danger btn-fill'
                  },
                  icon: 'error'
                })
              })
          } else {

          }
        })
      },
      statusToLabel(status) {
        if (status == 'IMPORTED') {
          return 'IMPORTADO'
        } else if (status === 'ASSIGNED') {
          return 'ASSOCIADO'
        } else if (status == 'UNASSIGNED') {
          return 'DESASSOCIADO'
        }
        return '??'
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
